import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  styled,
  Link,
  useTheme,
  Box,
  Stack,
} from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import { Close } from "@mui/icons-material";

export enum ModalSize {
  ExtraSmall = "xs",
  Small = "sm",
  Medium = "md",
  Large = "lg",
  ExtraLarge = "xl",
}

export interface MarvalModalProps {
  trigger: ReactNode;
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  modalSize?: ModalSize;
  onEnterKey?: () => void;
}

export const Modal = (props: MarvalModalProps) => {
  const theme = useTheme();
  const colors = theme.palette;
  const { open, onEnterKey } = props;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (onEnterKey && (event.key === 'Enter' || event.key === "NumpadEnter")) {
        event.preventDefault();
        onEnterKey();
      }
    };

    if (onEnterKey && open) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (onEnterKey) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [onEnterKey, open]);

  return (
    <>
      <Box display="flex">{props.trigger}</Box>
      <Dialog
        open={open}
        onClose={() => props.setOpen(false)}
        PaperProps={{
          sx: { borderRadius: "12px" },
        }}
        maxWidth={props.modalSize}
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            padding: "10px 15px 10px 20px",
            borderBottom: `1px solid ${colors.grey[200]}`,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>{props.header}</Box>
            <CloseLink onClick={() => props.setOpen(false)}>
              <Close sx={{ marginTop: 0.5 }} />
            </CloseLink>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px", display: "flex" }}>
          <Stack flex={1} pt="20px">
            {props.body}
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[50],
            borderTop: `1px solid ${colors.grey[200]}`,
            padding: "15px 25px",
          }}
        >
          {props.footer}
        </DialogActions>
      </Dialog>
    </>
  );
};

const CloseLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[700],
  "&:hover": {
    color: theme.palette.common.black,
    cursor: "pointer",
  },
}));
