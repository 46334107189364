import missingImage from "../../public/missing_image.png";
import ImageWithFallback from "./ImageWithFallback";

// Oversample the image api a bit to make stuff look better on high dpi displays
const OVERSAMPLE = 2;

function getLeagueClubCode(clubCode: string) {
  switch (clubCode) {
    case "CLV":
      return "CLE";
    case "ARZ":
      return "ARI";
    case "BLT":
      return "BAL";
    case "HST":
      return "HOU";
  }
  return clubCode;
}

export function ClubLogoImage(props: { clubCode: string; height: number }) {
  const width = props.height;
  const clubCode = getLeagueClubCode(props.clubCode);
  const logo = `https://static.www.nfl.com/c_fit,h_${
    OVERSAMPLE * props.height
  },w_${OVERSAMPLE * width}/league/api/clubs/logos/${clubCode}.png`;

  return (
    <ImageWithFallback
      height={props.height}
      width={width}
      layout="fixed"
      src={logo}
      alt="team logo"
      missingImage={missingImage}
    />
  );
}
